import React, { useEffect, useState }     from 'react';
import { useNavigate } from "react-router-dom";
import ContentLoader from "react-content-loader"

import CheckBoxYesNo from '../Utils/Inputs/CheckBoxYesNo';
import CustomInput from '../Utils/Inputs/CustomInput';
import api from '../Utils/API';
import { update_session}               from '../Utils/Session/Session';

const QualiteMoyenDeContact = props => {
    
    const navigate = useNavigate();
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#  VARIABLES AND STATE  #---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    const [componentLoaded, setComponentLoaded]     = useState(false);
    const [wasOpened, setWasOpened]                 = useState(false);
    const [isOpened, setIsOpened]                   = useState(false);
    const [isLoading, setIsLoading]                 = useState(false);

    const [inputsValues, setInputsValues] = useState({
        is_chkmail: undefined,
        chkmail_dt: undefined,
        valide_email: undefined,
        source_detail_abo_email: undefined,
        abo_email: undefined,
        is_traitement_rnvp: undefined,
        ava_traitement_rnvp_dt: undefined,
        valide_print: undefined,
        source_detail_abo_print: undefined,
        abo_print: undefined,
        valide_sms: undefined,
        source_detail_abo_sms: undefined,
        abo_sms: undefined,
        npai: undefined,
    });

    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#  METHODS  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#

    // --> component loading
    useEffect(() => {
        try{
            setComponentLoaded(true);
            return () => {
                setComponentLoaded(false);
            }
        }
        catch(error){
            props.setErrorHandler({hasError : true, code : "000209"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        try{
            if(componentLoaded){
                if(wasOpened){
                    setIsLoading(true);
                    if(!update_session()) props.deconnect();
                    else{
                        api.qualiteMoyenContact(parseInt(props.idContact))
                            .then((response) => {
                                if(componentLoaded){
                                    let status = response.request.status;
                                    if (status !== 200) {
                                        props.setErrorHandler({hasError : true, code : "000237"});
                                        return;
                                    }
                                    setInputsValues(response.data);
                                }
                            })
                            .catch((error) => {
                                if (error.response?.status === 401) {
                                    return navigate("/");
                                }
                                try{
                                    if(componentLoaded){
                                        // api key not authorized
                                        let status = error.response.request.status;
                                        if (status === 401) {
                                            if(error.response.data.errorMessage === undefined) props.setErrorHandler({hasError : true, code : "000234"});
                                            props.setErrorHandler({hasError : true, code : "000230"});
                                        }
                                        if (status === 500) {
                                            if(error.response.data.errorMessage === undefined) props.setErrorHandler({hasError : true, code : "000235"});
                                            props.setErrorHandler({hasError : true, code : "00023a"});
                                        } else {
                                            props.setErrorHandler({hasError : true, code : "00023b"});
                                        }
                                    }
                                }catch(error){
                                    props.setErrorHandler({hasError : true, code : "000239"});
                                }
                            })
                            .finally(() => {
                                setIsLoading(false)
                            });
                    }
                }
            }
        }
        catch(error){
            props.setErrorHandler({hasError : true, code : "00020a"});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wasOpened]);

    if(!(componentLoaded && props.fatherLoaded)) return(<></>);

    return (
        <div className={"container box"}>
            <div className={"row"}>
                <div className={"col padd0 onglet"}>
                    <CustomInput 
                        type={"checkbox"} 
                        className={"accordeon"} 
                        id={"accordeon1"} 
                        defaultChecked={isOpened} 
                        onChange={() => {setIsOpened(!isOpened); setWasOpened(true)}} 
                    />
                    <label htmlFor={"accordeon1"} className={"accordeon-title"}>Qualité des moyens de contact</label>
                </div>
            </div>
            {isOpened ?
                isLoading ? 
                    <ContentLoader 
                        speed={2}
                        width={800}
                        height={300}
                        viewBox="0 0 400 160"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <rect x="100" y="0" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="200" y="0" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="300" y="0" rx="0" ry="0" width="52" height="15" /> 

                        <rect x="0" y="28" rx="0" ry="0" width="800" height="1" /> 
                        
                        <rect x="0" y="45" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="100" y="45" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="200" y="45" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="300" y="45" rx="0" ry="0" width="52" height="15" /> 
                        
                        <rect x="0" y="70" rx="0" ry="0" width="800" height="1" /> 

                        <rect x="0" y="80" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="100" y="80" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="200" y="80" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="300" y="80" rx="0" ry="0" width="52" height="15" /> 
                        
                        <rect x="0" y="110" rx="0" ry="0" width="800" height="1" /> 

                        <rect x="0" y="120" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="100" y="120" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="200" y="120" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="300" y="120" rx="0" ry="0" width="52" height="15" /> 

                        <rect x="0" y="145" rx="0" ry="0" width="800" height="1" />
                    </ContentLoader>
                :
                    <div className={"bloc-1"}>  
                        <div className={"row borderBottom-thead"}>
                            <div className={"grid column-7"}>
                                <div>&nbsp;</div>
                                <div><span>Redressement</span></div>
                                <div><span>Date</span></div>
                                <div><span>Valide</span></div>
                                <div><span>Motif</span></div>
                                <div><span>Sollicitable</span></div>
                            </div>
                        </div>

                        <div className={"row borderBottom"}>
                            <div className={"grid column-7"}>
                                <div><b>Email</b></div>
                                <CheckBoxYesNo id={'is_chkmail'} checked={inputsValues.is_chkmail} centered={false} fatherLoaded={componentLoaded}></CheckBoxYesNo>
                                <div className={"center"}>
                                    <CustomInput type={"text"} id={'chkmail_dt'} value={inputsValues.chkmail_dt || ""} readOnly />
                                </div>
                                <CheckBoxYesNo id={'valide_email'} checked={inputsValues.valide_email} centered={false} fatherLoaded={componentLoaded}></CheckBoxYesNo>
                                <div className={"center"}>
                                    <CustomInput type={"text"} id={'source_detail_abo_email'} value={inputsValues.source_detail_abo_email || ""} readOnly />
                                </div>
                                <CheckBoxYesNo id={'abo_email'} checked={inputsValues.abo_email} centered={false} fatherLoaded={componentLoaded}></CheckBoxYesNo>
                            </div>
                        </div>
                        
                        
                        <div className={"row borderBottom"}>
                            <div className={"grid column-7"}>
                                <div><b>Adresse</b></div>
                                <CheckBoxYesNo id={'is_traitement_rnvp'} checked={inputsValues.is_traitement_rnvp} centered={false} fatherLoaded={componentLoaded}></CheckBoxYesNo>
                                <div className={"center"}>
                                    <CustomInput type={"text"} id={'ava_traitement_rnvp_dt'} value={inputsValues.ava_traitement_rnvp_dt || ""} readOnly />
                                </div>
                                <CheckBoxYesNo id={'valide_print'} checked={inputsValues.valide_print} centered={false} fatherLoaded={componentLoaded}></CheckBoxYesNo>
                                <div className={"center"}>
                                    <CustomInput type={"text"} id={'source_detail_abo_print'} value={inputsValues.source_detail_abo_print || ""} readOnly />
                                </div>
                                <CheckBoxYesNo id={'abo_print'} checked={inputsValues.abo_print} centered={false} fatherLoaded={componentLoaded}></CheckBoxYesNo>
                                <div className={"center"}>                                                
                                    <label htmlFor={'npai'}>NPAI</label>
                                    <CustomInput type={"text"} className={""} id={'npai'} value={inputsValues.npai ?? ""} readOnly />
                                </div>

                            </div>
                        </div>

                        
                        <div className={"row borderBottom"}>
                            <div className={"grid column-7"}>
                                <div><b>Mobile</b></div>
                                <div className={"center"}/>
                                <div className={"center"}/>
                                <CheckBoxYesNo id={'valide_sms'} checked={inputsValues.valide_sms} centered={false} fatherLoaded={componentLoaded}></CheckBoxYesNo>
                                <div className={"center"}>
                                    <CustomInput type={"text"} id={'source_detail_abo_sms'} value={inputsValues.source_detail_abo_sms || ""} readOnly />
                                </div>
                                <CheckBoxYesNo id={'abo_sms'} checked={inputsValues.abo_sms} centered={false} fatherLoaded={componentLoaded}></CheckBoxYesNo>
                            </div>
                        </div>
                    </div>
            :
                ""
            }
        </div>
    );
}

export default QualiteMoyenDeContact;