import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import ContentLoader from "react-content-loader"

import CheckBoxYesNo from '../Utils/Inputs/CheckBoxYesNo';
import CustomInput from '../Utils/Inputs/CustomInput';
import api from '../Utils/API';
import { update_session}               from '../Utils/Session/Session';
import { formatNumber } from 'Modules/Utils';


const QualificationComplementaire = props => {
    
    const navigate = useNavigate();
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#  VARIABLES AND STATE  #---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    const [componentLoaded, setComponentLoaded]     = useState(false);
    const [wasOpened, setWasOpened]                 = useState(false);
    const [isOpened, setIsOpened]                   = useState(false);
    const [isLoading, setIsLoading]                 = useState(false);
    const [inputsValues, setInputsValues]           = useState({
        segment : undefined,
        mag_appartenance : undefined,
        mag_prefere : undefined,
        canal_achat_prefere : undefined,
        source : undefined,
        creation_source_dt : undefined,
        modification_source_dt : undefined,
        is_porteur_carte_fid : undefined,
        num_carte_fid : undefined,
        creation_carte_fid_dt : undefined,
        solde_points_fid : undefined,
        mixite_canal : undefined,
        etat_client: undefined,
        qualite: undefined,
    });
    
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#  METHODS  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // --> component loading
    useEffect(() => {
        try{
            setComponentLoaded(true);
            return () => {
                setComponentLoaded(false);
            }
        }
        catch(error){
            props.setErrorHandler({hasError : true, code : "00020b"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        try{
            if(componentLoaded){
                if(wasOpened){
                    setIsLoading(true);
                    if(!update_session()) props.deconnect();
                    else{
                        api.qualificationComplementaire(parseInt(props.idContact))
                            .then((response) => {
                                if(componentLoaded){
                                    let status = response.request.status;
                                    if(status !== 200) {
                                        props.setErrorHandler({hasError : true, code : "000247"});
                                        return
                                    }
                                    setInputsValues(response.data);
                                }
                            })
                            .catch((error) => {
                                if (error.response?.status === 401) {
                                    return navigate("/");
                                }
                                try{
                                    if(componentLoaded){
                                        // api key not authorized
                                        let status = error.response.request.status;
                                        if (status === 401) {
                                            if(error.response.data.errorMessage === undefined) props.setErrorHandler({hasError : true, code : "000244"});
                                            props.setErrorHandler({hasError : true, code : "000240"});
                                        }
                                        if (status === 500) {
                                            if(error.response.data.errorMessage === undefined) props.setErrorHandler({hasError : true, code : "000245"});
                                            props.setErrorHandler({hasError : true, code : "00024a"});
                                        } else {
                                            props.setErrorHandler({hasError : true, code : "00024b"});
                                        }
                                    }
                                }catch(error){
                                    props.setErrorHandler({hasError : true, code : "000249"});
                                }
                            })
                            .finally(() => {
                                setIsLoading(false)
                            });
                    }
                }
            }
        }
        catch(error){
            props.setErrorHandler({hasError : true, code : "00020c"});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wasOpened]);

    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#   RENDER  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    if(!(componentLoaded && props.fatherLoaded)) return(<></>);
    return (
        <div className={"container box"}>
            <div className={"row"}>
                <div className={"col padd0 onglet"}>
                    <CustomInput type={"checkbox"} className={"accordeon"} id={"accordeon2"} defaultChecked={isOpened} onChange={() => {setIsOpened(!isOpened); setWasOpened(true)}} />
                    <label htmlFor={"accordeon2"} className={"accordeon-title"}>Qualifications complémentaires</label>
                </div>
            </div>
            {isOpened ?
                isLoading ?
                    <ContentLoader 
                        speed={2}
                        width={800}
                        height={300}
                        viewBox="0 0 400 160"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <rect x="100" y="0" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="200" y="0" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="300" y="0" rx="0" ry="0" width="52" height="15" /> 

                        <rect x="0" y="28" rx="0" ry="0" width="800" height="1" /> 
                        
                        <rect x="0" y="45" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="100" y="45" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="200" y="45" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="300" y="45" rx="0" ry="0" width="52" height="15" /> 
                        
                        <rect x="0" y="70" rx="0" ry="0" width="800" height="1" /> 

                        <rect x="0" y="80" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="100" y="80" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="200" y="80" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="300" y="80" rx="0" ry="0" width="52" height="15" /> 
                        
                        <rect x="0" y="110" rx="0" ry="0" width="800" height="1" /> 

                        <rect x="0" y="120" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="100" y="120" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="200" y="120" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="300" y="120" rx="0" ry="0" width="52" height="15" /> 

                        <rect x="0" y="145" rx="0" ry="0" width="800" height="1" />
                    </ContentLoader>
                :
                    <Fragment> 
                        <div className={"bloc-2"}>
                            <div className={"row"}>
                                <div className={"grid column-3D"}>
                                    <div>
                                        <label>Segmentation RFM </label>
                                        <span className={"infobulle"} aria-label={"Segment RFM du contact"}>?</span>
                                        <CustomInput type={"text"} className={"w-60"} id={'segment'} value={inputsValues.segment || ""} readOnly />
                                    </div>
                                    <div>
                                        <label>Magasin d'appartenance</label>
                                        <CustomInput type={"text"} className={"w-70"} id={'mag_appartenance'} value={inputsValues.mag_appartenance || ""} readOnly />
                                    </div>
                                    <div>
                                        <label>Magasin préféré</label>
                                        <CustomInput type={"text"} className={"w-70"} id={'mag_prefere'} value={inputsValues.mag_prefere || ""} readOnly />
                                    </div>
                                </div>
                            </div>
                        
                            <div className={"row"}>
                                <div className={"grid column-3"}>
                                    <div>
                                        <label>Origine contact</label>
                                        <CustomInput type={"text"} className={"w-65"} id={'source'} value={inputsValues.source || ""} readOnly />
                                    </div>

                                    <CheckBoxYesNo id={'mixite_canal'} checked={inputsValues.mixite_canal} label={"Cross canal :"} centered={true} fatherLoaded={componentLoaded} />
                                </div>
                            </div>
                        
                            <div className={"row"}>
                                <div className={"grid column-3"}>
                                    <CheckBoxYesNo id={'is_porteur_carte_fid'} checked={inputsValues.is_porteur_carte_fid} label={"Porteur de carte de fidélité :"} centered={true} fatherLoaded={componentLoaded} />
                                    <div>
                                        <label>N° carte de fidélité</label>
                                        <CustomInput type={"text"} id={'num_carte_fid'} value={inputsValues.num_carte_fid || ""} readOnly />
                                    </div>
                                    
                                    <div>
                                        <label>Solde de points de fidélité</label>
                                        <CustomInput type={"text"} id={'solde_points_fid'} value={formatNumber(inputsValues.solde_points_fid) || ""} readOnly />
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"grid column-3"}>
                                    <div>
                                        <label>Date de mise à jour de la carte</label>
                                        <CustomInput type={"text"} id={'creation_carte_fid_dt'} value={inputsValues.creation_carte_fid_dt || ""} readOnly />
                                    </div>
                                    <div>
                                        <label>Création de la fiche</label>
                                        <CustomInput type={"text"} className={"w-65"} id={'creation_source_dt'} value={inputsValues.creation_source_dt || ""} readOnly />
                                    </div>
                                    <div>
                                        <label>Mise à jour de la fiche</label>
                                        <CustomInput type={"text"} id={'modification_source_dt'} value={inputsValues.modification_source_dt || ""} readOnly />
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"grid column-3"}>
                                    <div>
                                        <label>Statut d'activité</label>
                                        <CustomInput type={"text"} id={'etat_client'} value={inputsValues.etat_client ?? ""} readOnly />
                                    </div>
                                    <div>
                                        <label>Importance</label>
                                        <CustomInput type={"text"} className={"w-65"} id={'qualite'} value={inputsValues.qualite ?? ""} readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </Fragment>
            :
                ""
            }
        </div>
    );
}
export default QualificationComplementaire;